







































import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import { ThingsFunctionEntityModel } from '@common-src/entity-model/things-function-entity';
import ThingsFunctionEditComponent from './things-function-edit.vue';
import ThingsAttributeDialog from '../things-attribute/things-attribute-edit.vue';
import { ThingsFunctionType, ViewModeType, ThingsEntityType } from '@common-src/model/enum';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { ThingsAttributeEntityModel } from '@common-src/entity-model/things-attribute-entity';
import { ThingsMethodEntityModel } from '@common-src/entity-model/things-method-entity';
import { ThingsEventEntityModel } from '@common-src/entity-model/things-event-entity';
import ThingsAttributeService from '@common-src/service/things-attribute';
import ThingsEventService from '@common-src/service/things-event';
import ThingsMethodService from '@common-src/service/things-method';

// const thingsAttributeService = new ThingsAttributeService();
// const thingsEventService = new ThingsEventService();
// const thingsMethodService = new ThingsMethodService();

@Component({
    components: {
        'things-function-edit-dialog': ThingsFunctionEditComponent,
        'things-attribute-dialog': ThingsAttributeDialog
    }
})
export default class ThingsFunctionListComponent extends BaseComponent {
    ThingsFunctionType = ThingsFunctionType;
    ThingsEntityType = ThingsEntityType;
    currentFunction: ThingsFunctionType = ThingsFunctionType.ATTRIBUTE;

    @Prop()
    thingsFunctionModel: ThingsFunctionEntityModel;
    @Prop()
    entityType: ThingsEntityType;

    get FunctionName(): string {
        return this.thingsFunctionModel.getFunctionName(this.currentFunction);
    }

    get TableColumns(): Array<any> {
        return _.filter(ThingsFunctionEntityModel.getFunctionTabelColumns(), (item: any) => (_.isUndefined(item.functionType) || item.functionType.indexOf(this.currentFunction) >= 0));
    }

    get FilterDataList(): Array<any> {
        return this.thingsFunctionModel.getFunctionList(this.currentFunction);
    }

    detailClick(model: any, viewMode = ViewModeType.UPDATE) {
        switch (this.currentFunction) {
            case ThingsFunctionType.ATTRIBUTE:
                if (!model) {
                    model = new ThingsAttributeEntityModel(this.entityType, this.thingsFunctionModel.id);
                }
                (this.$refs.attributeFormDialog as ThingsAttributeDialog).attributeDialogOpen(model, ThingsAttributeService as any, viewMode);
                break;
            case ThingsFunctionType.METHOD:
                if (!model) {
                    model = new ThingsMethodEntityModel(this.entityType, this.thingsFunctionModel.id);
                }
                (this.$refs.methodFormDialog as FormDialogComponent<ThingsMethodEntityModel>).dialogOpen(model, ThingsMethodService as any, viewMode);
                break;
            case ThingsFunctionType.EVENT:
                if (!model) {
                    model = new ThingsEventEntityModel(this.entityType, this.thingsFunctionModel.id);
                }
                (this.$refs.eventFormDialog as FormDialogComponent<ThingsEventEntityModel>).dialogOpen(model, ThingsEventService as any, viewMode);
                break;
        }
    }

    addFunctionClick() {
        // (this.$refs.functionEditFormDialog as any).dialogOpen(this.currentFunction, this.FilterDataList);
        this.detailClick(null, ViewModeType.NEW);
    }

    batchAddFunctionClick() {
        (this.$refs.functionEditFormDialog as any).dialogOpen(this.currentFunction, this.FilterDataList);
    }

    deleteFunctionClick(model: any) {
        this.startFullScreenLoading('正在删除...');
        this.thingsFunctionModel.deleteFunction(this.currentFunction, model)
            .then(res => {
                this.$emit('reload');
            })
            .finally(() => {
                this.stopFullScreenLoading();
            });
    }

    formDialogOK() {
        this.$emit('reload');
    }
}


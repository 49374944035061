









































































import { Component, Mixins } from 'vue-property-decorator';
import GroupComponent from '@common-src/mixins/group-component';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { ThingsTemplateEntityModel, ThingsTemplateQueryModel } from '@common-src/entity-model/things-template-entity';
import ThingsTemplateService from '@common-src/service/things-template';
import ThingsTemplateDetailDrawer from './things-template-detail-drawer.vue';
import ThingsSyncDialog from './things-sync-dialog.vue';
import { SyncType, ThingsEntityType } from '@common-src/model/enum';

@Component({
    components: {
        'things-template-detail-drawer': ThingsTemplateDetailDrawer,
        'things-sync-dialog': ThingsSyncDialog
    }
})
export default class ThingsTemplateListComponent extends Mixins(TableDialogFormComponent, GroupComponent) {
    ThingsTemplateEntityModel = ThingsTemplateEntityModel;
    selectedRowKeys = [];

    created() {
        this.initTable({
            service: ThingsTemplateService,
            queryModel: new ThingsTemplateQueryModel(),
            tableColumns: ThingsTemplateEntityModel.getTableColumns()
        });
    }

    treeSelect(groupIds: Array<string>) {
        const groupId = _.first(groupIds);
        (this.queryModel as ThingsTemplateQueryModel).groupIds = groupIds;
        if (groupId) {
            this.$router.push(`/dashboard/things-template-list?groupId=${groupId}`);
        } else {
            this.$router.push('/dashboard/things-template-list');
        }
    }
    detailClick(model: ThingsTemplateEntityModel, tabIndex?:string) {
        (this.$refs.thingsTemplateDetailDrawer as ThingsTemplateDetailDrawer).drawerShow(model, tabIndex);
    }

    upSysncClick() {
        (this.$refs.thingsSyncDialog as ThingsSyncDialog).dialogOpen(SyncType.UPLOAD, ThingsEntityType.TEMPLATE);
    }

    downSysncClick() {
        (this.$refs.thingsSyncDialog as ThingsSyncDialog).dialogOpen(SyncType.DOWNLOAD, ThingsEntityType.TEMPLATE);
    }

    publishSysncClick(model: ThingsTemplateEntityModel) {
        if (model.syncEnabled) {
            model.syncEnabled = false;
            ThingsTemplateService.publish(model.id).then((res) => {
                this.showMessageSuccess('发布成功');
                this.getList();
            }).catch().finally(() => {
                model.syncEnabled = true;
            });
        }
    }

    onSelectChange(selectedRowKeys) {
        this.selectedRowKeys = selectedRowKeys;
    }

    exportTemplate() {
        if (this.selectedRowKeys.length < 1) {
            this.showMessageWarning('请先选择要导出的产品模板');
            return;
        }
        let ids = this.selectedRowKeys.reduce((arr, item) => {
            const tr = item.match(/(\S*)\|/)[1];
            arr.push(tr);
            return arr;
        }, []);
        ThingsTemplateService.export(ids).then((res) => {
            this.showMessageSuccess('导出成功');
        }).catch();
    }
}

